<template>
    <div>
        <title-bar :title="$t('navigations.stockItems')"/>
        <columns-toggle
            :columns-array="defaultColumns"
            :action="showColumnsModal"
            @clearAction="showColumnsModal = false"
            @columnsChange="setColumns"
        />
        <advanced-filter
            :fields-array="filterFields"
            :action="showFilterModal"
            @clearAction="showFilterModal = false"
            @paramsChange="setAdvancedParams"
        />
        <div v-if="rows" class="custom-table">
            <table-actions
                :actions="['search', 'addBtn', 'secondaryBtn', 'perPage', 'columnsBtn', 'advancedFilter']"
                :addBtnText="$t('messages.stockItemsCreate')"
                :addBtnLink="$helper.getAddUrl('stock-items')"
                :secondaryBtnText="$t('messages.stockItemsCreateRotable')"
                secondaryBtnLink="/stock-items/add-rotable"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @columnsChange="showColumnsModal = true"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:createdAt="data">
                    {{ $helper.formatDate(data.value.createdAt) }}
                    {{ $helper.formatTime(data.value.createdAt) }}
                </template>
                <template v-slot:employee="data">
                    {{
                        data.value.employee.givenName + " " + data.value.employee.familyName
                    }}
                </template>
                <template v-slot:partNumber="data">
                    {{ data.value.partNumber }}
                </template>
                <template v-slot:description="data">
                    {{ data.value.description }}
                </template>
                <template v-slot:batchNumber="data">
                    {{ data.value.batchNumber }}
                </template>
                <template v-slot:quantity="data">
                    {{ data.value.quantity }}
                </template>
                <template v-slot:reorderNumber="data">
                    {{ data.value.reorderNumber }}
                </template>
                <template v-slot:reorderQuantity="data">
                    {{ data.value.reorderQuantity }}
                </template>
                <template v-slot:reorder="data">
                    <b-badge v-if="data.value.reorder <= 0" variant="danger">YES</b-badge>
                    <b-badge v-if="data.value.reorder > 0" variant="success">NO</b-badge>
                </template>
                <template v-slot:type="data">
                    <span v-if="data.value.type">
                        {{ data.value.type.name }}
                    </span>
                </template>
                <template v-slot:aeiNumber="data">
                    {{ data.value.aeiNumber }}
                </template>
                <template v-slot:effectivity="data">
                    {{ data.value.effectivity }}
                </template>
                <template v-slot:station="data">
                    {{ data.value.station }}
                </template>
                <template v-slot:location="data">
          <span v-if="data.value.location">
            {{ data.value.location.station.name }} - {{ data.value.location.stockItemLocation }}
          </span>
                </template>
                <template v-slot:owner="data">
          <span v-if="data.value.owner">
            {{ data.value.owner.name }}
          </span>
                </template>
                <template v-slot:orderNumber="data">
                    {{ data.value.orderNumber }}
                </template>
                <template v-slot:supplier="data">
                    {{ data.value.supplier }}
                </template>
                <template v-slot:price="data">
                    {{ data.value.price }}
                </template>
                <template v-slot:remark="data">
                    {{ data.value.remark }}
                </template>
                <template v-slot:icInterval="data">
                    <b-badge :variant="expiryStatus(data.value.icInterval)">
                        {{ $helper.formatDate(data.value.icInterval) }}
                    </b-badge>
                </template>
                <template v-slot:expiryDate="data">
                    <b-badge :variant="expiryStatus(data.value.expiryDate)">
                        {{ $helper.formatDate(data.value.expiryDate) }}
                    </b-badge>
                </template>
                <template v-slot:dangerous="data">
                    <b-badge v-if="data.value.dangerous" variant="danger">YES</b-badge>
                    <b-badge v-if="!data.value.dangerous" variant="success">NO</b-badge>
                </template>
                <template v-slot:heavy="data">
                    <b-badge v-if="data.value.heavy" variant="danger">YES</b-badge>
                    <b-badge v-if="!data.value.heavy" variant="success">NO</b-badge>
                </template>
                <template v-slot:status="data">
                    <b-badge v-if="data.value.status" :variant="statusClass(data.value.status)">{{
                            data.value.status
                        }}
                    </b-badge>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn
                            :path="$helper.getEditUrl('stock-items', data.value.id)"
                        />
                        <use-btn
                            :path="$helper.getEditUrl('stock-items', data.value.id)"
                        />
                        <delete-btn @pressDelete="deleteStock(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ColumnsToggle from "@/components/Table/ColumnsToggle.vue";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import UseBtn from "@/components/Buttons/UseBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import {getUserData} from "@/auth/utils";

export default {
    name: "AdminStockItemListing",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        EditBtn,
        UseBtn,
        DeleteBtn,
        TitleBar,
        ColumnsToggle,
        AdvancedFilter
    },
    mixins: [ResourceUtils],
    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showColumnsModal: false,
            showFilterModal: false,
            sortable: {
                order: "createdAt",
                sort: "desc",
            },
            filterFields: [
                {
                    label: 'forms.owner',
                    name: "owner",
                    value: null,
                    type: "entity",
                    entityName: "$StockItemOwners"
                },
                {
                    label: 'forms.type',
                    name: "type",
                    value: null,
                    type: "entity",
                    entityName: "$StockItemTypes"
                },
                {
                    label: 'forms.status',
                    name: "status",
                    value: null,
                    type: "enum",
                    enumName: "stock_item_status"
                },
                {
                    label: 'forms.station',
                    name: "station",
                    value: null,
                    type: "station",
                    entityName: "$Stations"
                },
                {
                    label: 'forms.expiryDate',
                    name: "expiryDate",
                    value: null,
                    type: "range"
                },
                {
                    label: 'forms.description',
                    name: "description",
                    value: null,
                    type: "text"
                },
                {
                    label: 'forms.partNumber',
                    name: "partNumber",
                    value: null,
                    type: "text"
                }
            ],
            advancedParams: [],
            columns: [],
            defaultColumns: [
                {
                    label: this.$t("forms.createdAt"),
                    field: "createdAt",
                    sortable: true,
                    enabled: true
                },
                {
                    label: this.$t("forms.partNumber"),
                    field: "partNumber",
                    sortable: true,
                    enabled: true
                },
                {
                    label: this.$t("forms.description"),
                    field: "description",
                    sortable: false,
                    enabled: true
                },
                {
                    label: this.$t("forms.batchNumber"),
                    field: "batchNumber",
                    sortable: true,
                    enabled: true
                },
                {
                    label: this.$t("forms.quantity"),
                    field: "quantity",
                    sortable: true,
                    enabled: true
                },
                {
                    label: this.$t("forms.reorderQuantity"),
                    field: "reorderQuantity",
                    sortable: true,
                    enabled: true
                },
                {
                    label: this.$t("forms.reorderNumber"),
                    field: "reorderNumber",
                    sortable: false,
                    enabled: false
                },
                {
                    label: this.$t("forms.type"),
                    field: "type",
                    sortable: true,
                    enabled: true
                },
                {
                    label: this.$t("forms.aeiNumber"),
                    field: "aeiNumber",
                    sortable: false,
                    enabled: true
                },
                {
                    label: this.$t("forms.effectivity"),
                    field: "effectivity",
                    sortable: false,
                    enabled: false
                },
                {
                    label: this.$t("forms.station"),
                    field: "station",
                    sortable: true,
                    enabled: true
                },
                {
                    label: this.$t("forms.stockItemLocation"),
                    field: "location",
                    sortable: true,
                    enabled: true
                },
                {
                    label: this.$t("forms.owner"),
                    field: "owner",
                    sortable: true,
                    enabled: true
                },
                {
                    label: this.$t("forms.orderNumber"),
                    field: "orderNumber",
                    sortable: false,
                    enabled: false
                },
                {
                    label: this.$t("forms.supplier"),
                    field: "supplier",
                    sortable: false,
                    enabled: false
                },
                {
                    label: this.$t("forms.price"),
                    field: "price",
                    sortable: false,
                    enabled: true
                },
                {
                    label: this.$t("forms.remark"),
                    field: "remark",
                    sortable: false,
                    enabled: false
                },
                {
                    label: this.$t("forms.reorder"),
                    field: "reorder",
                    sortable: true,
                    enabled: true
                },
                {
                    label: this.$t("forms.icInterval"),
                    field: "icInterval",
                    sortable: true,
                    enabled: true
                },
                {
                    label: this.$t("forms.expiryDate"),
                    field: "expiryDate",
                    sortable: true,
                    enabled: true
                },
                {
                    label: this.$t("forms.dangerous"),
                    field: "dangerous",
                    sortable: true,
                    enabled: false
                },
                {
                    label: this.$t("forms.heavy"),
                    field: "heavy",
                    sortable: true,
                    enabled: false
                },
                {
                    label: this.$t("forms.status"),
                    field: "status",
                    sortable: true,
                    enabled: true
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "100px",
                    enabled: true
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };
            params["quantity[gt]"] = 0;
            if (this.sortable.order === 'location') {
                params["order[location.station.name]"] = this.sortable.sort;
                params["order[location.stockItemLocation]"] = this.sortable.sort;
            } else {
                params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            }
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            if(this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_ENGINEER)) {
                params['station'] = getUserData()['station']['name']
            }
            this.advancedParams.forEach(element => {
                if (element.value !== '' && element.value !== null) {
                    params[element.name] = element.value
                }
            });
            this.$StockItems.getCollection({params}, "stock_item_list").then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.sort = sort;
            this.sortable.order = order;
            if (order === 'owner' || order === 'type') {
                this.sortable.order = order + '.name';
            }
            if (order === 'employee') {
                this.sortable.order = order + '.familyName';
            }
            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        deleteStock(url) {
            this.deleteByUrl(this.$StockItems, url, this.$t("messages.stockItemsDeleted"), null, this.load, this.error);
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        error() {
            this.$emit("clearAction");
        },
        setColumns(data) {
            this.columns = data
        },
        setAdvancedParams(data) {
            this.advancedParams = data
            this.load()
        },
        statusClass(status) {
            let className = 'success'
            if (status === 'UNSERVICEABLE') {
                className = 'danger'
            }
            if (status === 'QUARANTINE') {
                className = 'warning'
            }
            return className
        },
        expiryStatus(date) {
            let className = 'success'
            let expiryDate = new Date(date)
            let currentDate = new Date()
            let twoMonths = 60 * 24 * 60 * 60 * 1000
            if (expiryDate.getTime() - currentDate.getTime() < twoMonths) {
                className = 'danger'
            }
            return className
        }
    },
};
</script>
